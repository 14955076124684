<template>
  <div class="organizer-dashboard">
    <button @click="toggleCompetitionForm" class="toggle-form-btn">
      {{
        showCompetitionForm ? 'Hide Competition Form' : 'Create New Competition'
      }}
    </button>

    <transition name="fade">
      <div v-if="showCompetitionForm" class="competition-form">
        <h3>{{ editingCompetition ? 'Edit' : 'Create' }} Competition</h3>
        <form @submit.prevent="saveCompetition">
          <label>Competition Name / Header Image</label>
          <input
            v-model="competition.name"
            type="text"
            placeholder="Competition Name or Image URL"
            required
            @input="checkImageUrl"
          />
          <img
            v-if="isImageUrl"
            :src="competition.name"
            alt="Competition Logo"
            class="competition-logo-preview"
          />
          <label>Footer Text / Footer Image</label>
          <input
            v-model="competition.footer"
            type="text"
            placeholder="Footer Text or Image URL"
            @input="checkFooterImageUrl"
          />
          <img
            v-if="isFooterImageUrl"
            :src="competition.footer"
            alt="Footer Image"
            class="footer-image-preview"
          />
          <hr />
          <label>XML URL</label>
          <input
            v-model="competition.xmlUrl"
            type="url"
            placeholder="XML URL"
            required
            @blur="fetchAvailableTags"
          />
          <label>Default Refresh Interval (seconds)</label>
          <input
            v-model.number="competition.refreshInterval"
            type="number"
            placeholder="Refresh Interval (seconds)"
            required
          />
          <label for="showNationFlags">Show Nation Flags:</label>
          <select id="showNationFlags" v-model="competition.showNationFlags">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          <label for="showCombinedView">Show Combined View:</label>
          <select id="showCombinedView" v-model="competition.showCombinedView">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          <hr />
          <div
            v-if="competition.showCombinedView === 'yes'"
            class="combined-view-translations"
          >
            <label>Combined View Translations</label>
            <label>Overall Rank</label>
            <input
              v-model="competition.translations['Overall Rank']"
              type="text"
              placeholder="Translation for Overall Rank"
            />
            <label>Time Loss</label>
            <input
              v-model="competition.translations['Time Loss']"
              type="text"
              placeholder="Translation for Time Loss"
            />
            <hr />
          </div>
          <div class="headers-section">
            <h4>Headers</h4>
            <div
              v-for="(header, index) in competition.headers"
              :key="index"
              class="header-item"
            >
              <button
                type="button"
                @click="moveHeader(index, -1)"
                :disabled="index === 0"
                class="move-btn"
              >
                ↑
              </button>
              <button
                type="button"
                @click="moveHeader(index, 1)"
                :disabled="index === competition.headers.length - 1"
                class="move-btn"
              >
                ↓
              </button>
              <select v-model="competition.headers[index]">
                <option value="">Select a field</option>
                <option
                  v-for="field in availableTags"
                  :key="field"
                  :value="field"
                >
                  {{ field }}
                </option>
              </select>
              <input
                v-model="competition.translations[header]"
                type="text"
                :placeholder="`Translation for ${header}`"
              />
              <button type="button" @click="removeHeader(index)">Remove</button>
            </div>
            <button type="button" @click="addHeader">Add Header</button>
          </div>

          <div class="form-actions">
            <button type="submit" class="btn btn-primary">
              Save Competition
            </button>
            <button
              type="button"
              @click="cancelEdit"
              v-if="editingCompetition"
              class="btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </transition>

    <div class="competitions-list">
      <h3>Your Competitions</h3>
      <div v-if="competitions.length > 0" class="table-responsive">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="comp in competitions" :key="comp.id">
              <td>{{ comp.id }}</td>
              <td>{{ comp.name }}</td>
              <td>
                <div style="display: flex; gap: 0.5rem">
                  <button
                    @click="editCompetition(comp)"
                    class="btn btn-small btn-primary"
                  >
                    Edit
                  </button>
                  <button
                    @click="showEmbedCode(comp)"
                    class="btn btn-small btn-secondary"
                  >
                    Embed Code
                  </button>
                  <button
                    @click="deleteCompetition(comp.id)"
                    class="btn btn-small btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else class="no-competitions">No competitions found.</p>
    </div>

    <transition name="fade">
      <div v-if="showEmbed" class="embed-code">
        <h3>Embed Code for {{ selectedCompetition.name }}</h3>
        <pre><code>{{ embedCode }}</code></pre>
        <button @click="copyEmbedCode" class="btn btn-primary">
          Copy Code
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'

export default {
  setup() {
    const competitions = ref([])
    const competition = ref({
      name: '',
      xmlUrl: '',
      refreshInterval: 60,
      headers: [],
      translations: {},
      footer: '',
      showCombinedView: 'no',
      showNationFlags: 'no',
    })
    const editingCompetition = ref(false)
    const showEmbed = ref(false)
    const selectedCompetition = ref(null)
    const embedCode = ref('')
    const availableTags = ref([])
    const showCompetitionForm = ref(false)

    const isImageUrl = ref(false)
    const isFooterImageUrl = ref(false)

    const checkImageUrl = () => {
      const url = competition.value.name
      isImageUrl.value = url.match(/\.(jpeg|jpg|gif|png)$/) != null
    }

    const checkFooterImageUrl = () => {
      const url = competition.value.footer
      isFooterImageUrl.value = url.match(/\.(jpeg|jpg|gif|png)$/) != null
    }

    const toggleCompetitionForm = () => {
      showCompetitionForm.value = !showCompetitionForm.value
      if (!showCompetitionForm.value) {
        resetForm()
      }
    }

    const resetForm = () => {
      competition.value = {
        name: '',
        xmlUrl: '',
        refreshInterval: 60,
        headers: [],
        translations: {},
        footer: '',
        showCombinedView: 'no',
        showNationFlags: 'no',
      }
      editingCompetition.value = false
      availableTags.value = []
    }

    const cancelEdit = () => {
      resetForm()
      showCompetitionForm.value = false
    }

    const editCompetition = (comp) => {
      competition.value = {
        id: comp.id,
        name: comp.name,
        xmlUrl: comp.xmlUrl,
        refreshInterval: comp.refreshInterval,
        headers: Array.isArray(comp.headers)
          ? comp.headers
          : JSON.parse(comp.headers),
        translations:
          typeof comp.translations === 'object'
            ? comp.translations
            : JSON.parse(comp.translations),
        footer: comp.footer || '',
        showCombinedView: comp.showCombinedView || 'no',
        showNationFlags: comp.showNationFlags || 'no',
      }
      editingCompetition.value = true
      showCompetitionForm.value = true
      fetchAvailableTags()
      checkImageUrl()
      checkFooterImageUrl()
    }

    const fetchAvailableTags = async () => {
      if (!competition.value.xmlUrl) return
      try {
        const response = await axios.get('/competition/available-tags', {
          params: { xmlUrl: competition.value.xmlUrl },
        })
        availableTags.value = response.data
      } catch (error) {
        alert(
          'Failed to fetch available tags. Please check the XML URL and try again.'
        )
      }
    }

    const fetchCompetitions = async () => {
      try {
        const response = await axios.get('/organizer/competitions')
        competitions.value = response.data
          .map((comp) => ({
            id: comp.id,
            name: comp.name,
            xmlUrl: comp.xml_url,
            refreshInterval: comp.refresh_interval,
            headers: JSON.parse(comp.headers),
            translations: JSON.parse(comp.translations),
            footer: comp.footer,
            showCombinedView: comp.showCombinedView,
            showNationFlags: comp.show_nation_flags,
            organizerId: comp.organizer_id,
          }))
          .sort((a, b) => b.id - a.id) // Sort based on id
        console.log(competitions.value)
      } catch (error) {
        console.error('Error fetching competitions:', error)
        alert('Failed to fetch competitions. Please try again later.')
      }
    }

    const saveCompetition = async () => {
      try {
        const response = await axios.post('/competition', competition.value)
        if (editingCompetition.value) {
          const index = competitions.value.findIndex(
            (c) => c.id === response.data.id
          )
          if (index !== -1) {
            competitions.value[index] = {
              ...competitions.value[index],
              ...competition.value,
            }
          }
        } else {
          competitions.value.push({
            ...competition.value,
            id: response.data.id,
          })
        }
        resetForm()
        showCompetitionForm.value = false
        // alert('Competition saved successfully!')
      } catch (error) {
        console.error('Error saving competition:', error)
        if (error.response) {
          console.error('Error response:', error.response.data)
          console.error('Error status:', error.response.status)
        } else if (error.request) {
          console.error('No response received:', error.request)
        } else {
          console.error('Error setting up request:', error.message)
        }
        alert('Failed to save competition. Please try again.')
      }
    }

    const deleteCompetition = async (competitionId) => {
      if (confirm('Are you sure you want to delete this competition?')) {
        try {
          await axios.delete(`/competition/${competitionId}`)
          await fetchCompetitions()
        } catch (error) {
          console.error('Error deleting competition:', error)
          alert('Failed to delete competition. Please try again.')
        }
      }
    }

    const addHeader = () => {
      competition.value.headers.push('')
    }

    const removeHeader = (index) => {
      competition.value.headers.splice(index, 1)
    }

    const moveHeader = (index, direction) => {
      const newIndex = index + direction
      if (newIndex >= 0 && newIndex < competition.value.headers.length) {
        const headers = competition.value.headers
        const temp = headers[index]
        headers[index] = headers[newIndex]
        headers[newIndex] = temp
      }
    }

    const showEmbedCode = (comp) => {
      if (showEmbed.value && selectedCompetition.value === comp) {
        showEmbed.value = false
      } else {
        selectedCompetition.value = comp
        const baseUrl = process.env.VUE_APP_BASE_URL || window.location.origin
        embedCode.value = `<script src="${baseUrl}/sport-results.js"><\/script>
<script>
  initSportResults('${comp.id}', '${comp.organizerId}');
<\/script>`
        showEmbed.value = true
      }
    }

    const copyEmbedCode = () => {
      navigator.clipboard
        .writeText(embedCode.value)
        .then(() => alert('Embed code copied to clipboard!'))
        .catch((err) => console.error('Error copying embed code:', err))
    }

    onMounted(fetchCompetitions)

    return {
      competitions,
      competition,
      editingCompetition,
      showEmbed,
      selectedCompetition,
      embedCode,
      availableTags,
      showCompetitionForm,
      toggleCompetitionForm,
      cancelEdit,
      fetchAvailableTags,
      saveCompetition,
      editCompetition,
      addHeader,
      removeHeader,
      moveHeader,
      showEmbedCode,
      copyEmbedCode,
      deleteCompetition,
      isImageUrl,
      checkImageUrl,
      isFooterImageUrl,
      checkFooterImageUrl,
    }
  },
}
</script>

<style scoped>
hr {
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
}
.organizer-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
}

.toggle-form-btn {
  display: block;
  margin: 0 auto 2rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.toggle-form-btn:hover {
  background-color: #2980b9;
  transform: translateY(-1px);
}

.toggle-form-btn:active {
  transform: translateY(1px);
}

.competition-form {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.competition-form h3 {
  margin-top: 0;
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.competition-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.competition-form input[type='text'],
.competition-form input[type='url'],
.competition-form input[type='number'],
.competition-form select {
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f8f9fa;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.competition-form label {
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
}

.competition-form input[type='text']:focus,
.competition-form input[type='url']:focus,
.competition-form input[type='number']:focus,
.competition-form select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.headers-section {
  margin-top: 1rem;
}

.headers-section h4 {
  font-size: 1.1rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.header-item {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
}

.header-item select,
.header-item input {
  flex: 1;
}

.header-item button {
  padding: 0.5rem 1rem;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.header-item button:hover {
  background-color: #c0392b;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.form-actions button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.form-actions button:hover {
  transform: translateY(-1px);
}

.form-actions button:active {
  transform: translateY(1px);
}

.competitions-list h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 600;
}

.table-responsive {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

th,
td {
  text-align: left;
  padding: 0.75rem;
  border-bottom: 1px solid #dee2e6;
}

th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #2c3e50;
}

tr:hover {
  background-color: #f8f9fa;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #3498db;
  color: white;
}

.btn-primary:hover {
  background-color: #2980b9;
}

.btn-secondary {
  background-color: #95a5a6;
  color: white;
}

.btn-secondary:hover {
  background-color: #7f8c8d;
}

.btn-danger {
  background-color: #e74c3c;
  color: white;
}

.btn-danger:hover {
  background-color: #c0392b;
}

.btn-small {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.no-competitions {
  text-align: center;
  color: #7f8c8d;
  font-style: italic;
}

.embed-code {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.embed-code h3 {
  margin-top: 0;
  color: #2c3e50;
}

.embed-code pre {
  background-color: #ecf0f1;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.move-btn {
  padding: 0.25rem 0.5rem;
  background-color: #3498db !important;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.move-btn:hover {
  background-color: #2980b9 !important;
}

.move-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.competition-logo-preview {
  max-height: 100px;
  max-width: 100%;
  object-fit: contain;
}

.footer-image-preview {
  max-height: 100px;
  max-width: 100%;
  object-fit: contain;
}

.combined-view-translations {
  border-radius: 4px;
}

.combined-view-translations h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.combined-view-translations label {
  text-align: left;
  display: block;
  margin-bottom: 1rem;
}
.combined-view-translations input {
  width: 100%;
  margin-bottom: 1rem;
}
</style>
