<template>
  <div class="organizer-view">
    <h1>Organizer Dashboard</h1>
    <OrganizerDashboard />
  </div>
</template>

<script>
import OrganizerDashboard from '@/components/OrganizerDashboard.vue'

export default {
  components: {
    OrganizerDashboard,
  },
}
</script>
