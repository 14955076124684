<template>
  <div class="login-container">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin">
      <div>
        <label for="email">Email:</label>
        <input
          type="email"
          id="email"
          v-model="email"
          @blur="validateEmail"
          required
        />
        <p v-if="emailError" class="error">{{ emailError }}</p>
      </div>
      <div>
        <label for="password">Password:</label>
        <input
          type="password"
          id="password"
          v-model="password"
          @blur="validatePassword"
          required
        />
        <p v-if="passwordError" class="error">{{ passwordError }}</p>
      </div>
      <button type="submit" :disabled="!isFormValid">Login</button>
    </form>
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref('')
    const emailError = ref('')
    const passwordError = ref('')
    const router = useRouter()
    const store = useStore()

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!email.value) {
        emailError.value = 'Email is required'
      } else if (!emailRegex.test(email.value)) {
        emailError.value = 'Please enter a valid email address'
      } else {
        emailError.value = ''
      }
    }

    const validatePassword = () => {
      if (!password.value) {
        passwordError.value = 'Password is required'
      } else if (password.value.length < 6) {
        passwordError.value = 'Password must be at least 6 characters long'
      } else {
        passwordError.value = ''
      }
    }

    const isFormValid = computed(() => {
      return (
        email.value &&
        password.value &&
        !emailError.value &&
        !passwordError.value
      )
    })

    const handleLogin = async () => {
      validateEmail()
      validatePassword()
      if (!isFormValid.value) {
        return
      }

      try {
        error.value = '' // Clear previous errors
        await store.dispatch('login', {
          email: email.value,
          password: password.value,
        })
        const userRole = store.state.user.role
        if (userRole === 'admin') {
          router.push('/admin')
        } else if (userRole === 'organizer') {
          router.push('/organizer')
        }
      } catch (err) {
        console.error('Login failed:', err)
        error.value =
          (err.response && err.response.data && err.response.data.message) ||
          'An error occurred during login'
      }
    }

    return {
      email,
      password,
      error,
      emailError,
      passwordError,
      handleLogin,
      validateEmail,
      validatePassword,
      isFormValid,
    }
  },
}
</script>

<style scoped>
.login-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

button {
  margin-top: 10px;
}

.error-message,
.error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}
</style>
