import { createStore } from 'vuex'
import axios from 'axios'
import {jwtDecode} from 'jwt-decode'

export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || null,
    error: null,
    logoutTimer: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
    setError(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = null
    },
    logout(state) {
      state.user = null
      state.token = null
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']
      if (state.logoutTimer) {
        clearTimeout(state.logoutTimer)
      }
    },
    setLogoutTimer(state, timer) {
      state.logoutTimer = timer
    },
  },
  actions: {
    async login({ commit, dispatch }, { email, password }) {
      commit('clearError')
      try {
        const response = await axios.post('/auth/login', { email, password })
        const { user, token } = response.data
        commit('setUser', user)
        commit('setToken', token)
        dispatch('setAutoLogout')
      } catch (error) {
        console.error('Login failed:', error)
        let errorMessage = 'An unexpected error occurred'
        if (error.response) {
          errorMessage =
            error.response.data.message ||
            `Server error: ${error.response.status}`
          console.error('Error data:', error.response.data)
          console.error('Error status:', error.response.status)
          console.error('Error headers:', error.response.headers)
        } else if (error.request) {
          errorMessage =
            'No response received from server. Please check your network connection and try again.'
          console.error('Error request:', error.request)
        } else {
          errorMessage = error.message
        }
        commit('setError', errorMessage)
        throw error
      }
    },
    logout({ commit }) {
      commit('logout')
      window.location.reload()
    },
    initializeAuth({ commit, dispatch }) {
      const token = localStorage.getItem('token')
      const user = JSON.parse(localStorage.getItem('user'))
      if (token && user) {
        try {
          const decoded = jwtDecode(token)
          const currentTime = Date.now() / 1000
          if (decoded.exp && decoded.exp > currentTime) {
            commit('setToken', token)
            commit('setUser', user)
            dispatch('setAutoLogout', decoded.exp - currentTime)
          } else {
            dispatch('logout')
          }
        } catch (e) {
          console.error('Invalid token:', e)
          dispatch('logout')
        }
      }
    },
    setAutoLogout({ commit, dispatch }, duration = null) {
      if (duration === null && this.state.token) {
        try {
          const decoded = jwtDecode(this.state.token)
          const currentTime = Date.now() / 1000
          duration = decoded.exp - currentTime
        } catch (e) {
          console.error('Failed to decode token for auto logout:', e)
          duration = 0
        }
      }
      if (duration <= 0) {
        dispatch('logout')
        return
      }
      const timer = setTimeout(() => {
        dispatch('logout')
      }, duration * 1000)
      commit('setLogoutTimer', timer)
    },
  },
  getters: {
    isAuthenticated: (state) => {
      if (!state.token) return false
      try {
        const decoded = jwtDecode(state.token)
        const currentTime = Date.now() / 1000
        return decoded.exp && decoded.exp > currentTime
      } catch (e) {
        console.error('Invalid token:', e)
        return false
      }
    },
    userRole: (state) => (state.user ? state.user.role : null),
    getError: (state) => state.error,
  },
})
