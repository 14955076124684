<template>
  <div id="app">
    <nav v-if="isAuthenticated">
      <router-link to="/admin" v-if="userRole === 'admin'">Admin Dashboard</router-link>
      <router-link to="/organizer" v-if="userRole === 'organizer'">Organizer Dashboard</router-link>
      <a href="#" @click.prevent="handleLogout">Logout</a>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const router = useRouter()

    const isAuthenticated = computed(() => store.getters.isAuthenticated)
    const userRole = computed(() => store.getters.userRole)

    const handleLogout = () => {
      store.dispatch('logout')
      router.push('/')
    }

    return {
      isAuthenticated,
      userRole,
      handleLogout,
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  margin: 0 10px;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
