<template>
  <div class="admin-view">
    <h1>Admin Dashboard</h1>
    <AdminDashboard />
  </div>
</template>

<script>
import AdminDashboard from '@/components/AdminDashboard.vue'

export default {
  components: {
    AdminDashboard,
  },
}
</script>
