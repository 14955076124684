<template>
  <div class="admin-dashboard">
    <h2>Admin Dashboard</h2>

    <div class="add-organizer">
      <h3>Add Organizer</h3>
      <form @submit.prevent="addOrganizer">
        <input
          v-model="newOrganizer.email"
          type="email"
          placeholder="Email"
          required
        />
        <input
          v-model="newOrganizer.password"
          type="password"
          placeholder="Password"
          required
        />
        <button type="submit">Add Organizer</button>
      </form>
    </div>

    <div class="users-list">
      <h3>Users</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="competitions-list">
      <h3>Competitions</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Organizer</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="competition in competitions" :key="competition.id">
            <td>{{ competition.id }}</td>
            <td>{{ competition.name }}</td>
            <td>{{ competition.organizer_email }}</td>
            <td>
              <button
                @click="deleteCompetition(competition.id)"
                class="delete-btn"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'

export default {
  setup() {
    const users = ref([])
    const competitions = ref([])
    const newOrganizer = ref({ email: '', password: '' })

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/admin/users')
        users.value = response.data
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }

    const fetchCompetitions = async () => {
      try {
        const response = await axios.get('/admin/competitions')
        competitions.value = response.data
      } catch (error) {
        console.error('Error fetching competitions:', error)
      }
    }

    const addOrganizer = async () => {
      try {
        await axios.post('/admin/add-organizer', newOrganizer.value)
        newOrganizer.value = { email: '', password: '' }
        await fetchUsers()
      } catch (error) {
        console.error('Error adding organizer:', error)
      }
    }

    const deleteCompetition = async (competitionId) => {
      if (confirm('Are you sure you want to delete this competition?')) {
        try {
          await axios.delete(`/competition/${competitionId}`)
          await fetchCompetitions()
        } catch (error) {
          console.error('Error deleting competition:', error)
          alert('Failed to delete competition. Please try again.')
        }
      }
    }

    onMounted(() => {
      fetchUsers()
      fetchCompetitions()
    })

    return {
      users,
      competitions,
      newOrganizer,
      addOrganizer,
      deleteCompetition,
    }
  },
}
</script>

<style scoped>
.admin-dashboard {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.add-organizer {
  margin-bottom: 20px;
}

form {
  display: flex;
  gap: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.delete-btn {
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #ff1a1a;
}
</style>
